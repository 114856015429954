.certificate-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}
.certificate-box img{
    width: 270px;
    height: 400px;
}
.certificate-box .btn-a{
    font-size: 8px;
}