.navbar{
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    background-color: transparent;
    backdrop-filter: blur(16px);
}
.navbar-left img{
    width: 180px;
}
.navbar-right{
    display: flex;
    gap: 2rem;
    align-items: center;
}
.navbar-right .nav-items:hover{
    color: var(--orange);
}
.nav-items.active {
    color: var(--orange);
}
.navbar .menu-icon{
    cursor: pointer;
    display: none;
}
.side-menu{
    position: fixed;
    top: 0%;
    right: 0%;
    width: 80vw;
    height: 100vh;
    background-color: rgb(238, 238, 238);
    z-index: 1000;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 2rem 2rem;
}
.side-menu img{
    width: 120px;
}
.side-menu .close-icon{
    position: absolute;
    top: 3%;
    right: 3%;
    font-size: 20px;
    border-radius: 50%;
    cursor: pointer;
}
.whatsapp-box{
    position: fixed;
    bottom: 3%;
    right: 3%;
    background-color: #25D366;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1111;
    cursor: pointer;
}
.whatsapp-box .whatsapp-icon{
    color: white;
    filter: invert(10%);
    font-size: 40px;
}
@media screen and (max-width:750px) {
    .navbar-right{
        display: none;
    }
    .navbar .menu-icon{
        display: inline-block;
    }
}

@media screen and (max-width:500px) {
    .navbar-left img{
        width: 100px;
    }
    .side-menu .nav-items{
        font-size: 12px;
    }
}