.landing-page{
    min-height: 100vh;
    width: 100%;
}
.landing-section{
    padding: 2rem 2rem;
    padding-top:120px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    position: relative;
}
.landing-section .curved-line{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    object-fit: cover;
    opacity: 0.3;
    z-index: 10;
}
.landing-section-left{
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    z-index: 11;
}
.landing-section-left .btn-a{
    width: 160px;
    margin-top: 1rem;
}
.landing-section-left h2 span {
    background: -webkit-linear-gradient( 60deg ,var(--orange), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.landing-section-right{
    z-index: 11;
}
.landing-section-right img{
    width: 330px;
}
.about-section{
    margin-top: 2rem;
    padding: 4rem 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
    background-color:rgb(244, 244, 244);
}
.about-section-right{
    display: flex;
    flex-direction:column;
    gap: 1rem;
    max-width: 600px;
}
.about-section-left img{
    width: 300px;
}
.about-section-right h3{
    color: var(--orange);
}
.about-section-right .btn-a{
    width: 200px;
}

.division-section{
    width: 100%;
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}
.division-section h3{
    text-align: center;
}
.division-section h3 span{
    color: var(--orange);
}
.division-row{
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    gap: 1.5rem;
}


.service-section{
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.service-section-top{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    align-items: center;
}
.service-section-top p{
    max-width: 600px;
    font-size: 12px;
}
.service-section-top h3{
    color: var(--orange);
}
.service-section-bottom{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
}
.service-section-bottom-right img{
    width: 300px;
}
.service-section-content{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.service-section-bottom-left{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}



.offer-section{
    padding: 6rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    text-align: center;
    position: relative;
    background: url("../../images/offer section.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
.offer-section p{
    max-width: 70%;
    position: relative;
    color: white;
    font-size: 12px;
}
.offer-section p:nth-child(3){
    width: 50%;
}
.offer-section p:nth-child(4){
    width: 50%;
}
.offer-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
}
.offer-section-row{
    position: relative;
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.offer-section-row-data h3{
    color: var(--orange);
}
.offer-section-row-data p{
    max-width: 100%;
}


.certificate-section{
    padding: 2rem 2rem;
    padding-top: 4rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
}
.certificate-section-left{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    max-width: 500px;
}
.certificate-section-left .btn-a{
    width: 200px;
}
.certificate-section-left h3 span{
    color: var(--orange);
}
.certificate-section-right img{
    width: 300px;
}

.home-page-gallery-section{
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
}
.home-page-gallery-section h3 span{
    color: var(--orange);
}


@media screen and (max-width:900px) {
    .landing-section-left p{
        font-size: 12px;
    }
    .landing-section-right img{
        width: 250px;
    }
    .about-section-right p{
        font-size: 12px;
    }
}
@media screen and (max-width:800px) {
    .about-section-left img{
        width: 250px;
    }
    .certificate-section-left p{
        font-size: 12px;
    }
}
@media screen and (max-width:750px) {
   .landing-section{
    flex-direction: column;
   }
   .landing-section-left{
    align-items: center;
    text-align: center;
   }
   .landing-section-right img{
    width: 330px;
   }
   .service-section-bottom-right img{
    width: 200px;
   }
}
@media screen and (max-width:700px) {
    .about-section{
        flex-wrap: wrap-reverse;
    }
    .about-section-right{
        align-items: center;
        text-align: center;
    }
    .about-section-left img{
        width: 300px;
    }
    .division-row{
        flex-wrap: wrap;
    }
    .service-section-bottom{
        flex-direction: column;
    }
    .service-section-bottom-right img{
        width: 250px;
    }
    .certificate-section{
        flex-direction: column;
        padding-bottom: 0;
    }
    .certificate-section-left{
        align-items: center;
        text-align: center;
    }
    .home-page-gallery-section{
        padding-top: 0;
    }
}
@media screen and (max-width:600px) {
    .offer-section p{
        max-width: 100%;
    }
    .offer-section p:nth-child(3){
        width: 90%;
    }
    .offer-section p:nth-child(4){
        width: 80%;
    }
}
@media screen and (max-width:500px) {
    .landing-section-left h2{
        font-size: 2rem;
    }
    .landing-section-left .btn-a{
        font-size: 12px;
    }
    .landing-section-right img{
        width: 300px;
    }
}
@media screen and (max-width:400px) {
    .landing-page p{
        font-size: 10px;
    }
    .about-section img{
        width: 250px;
    }
}
@media screen and (max-width:350px) {
    .landing-section-left h2{
        font-size: 1.5rem;
    }
}