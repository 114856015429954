.service-section-content-top{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.count-round-box{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--orange);
    display: flex;
    justify-content: center;
    align-items: center;
}
.count-round-box h4{
    color: white;
}
.service-section-content-top > h4{
    color: var(--blue);
}
.service-section-content-bottom p{
    font-size: 12px;
    max-width: 500px;
    padding-left: 3rem;
}

@media screen and (max-width:600px) {
    .service-section-content-top h4{
        font-size: 0.8rem;
    }
    .service-section-content-top p{
        font-size: 8px;
    }
    .count-round-box{
        width: 25px;
        height: 25px;
    }
    .count-round-box h4{
        font-size: 0.6rem;
    }
}