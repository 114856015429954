.slider-component{
    position: relative;
    padding: 2rem 2rem;
}
.carousel{
    display: flex;
    gap: 2rem;
    overflow-x: auto;
    position: relative;
    width: 100%;
}
.carousel img{
    width: 270px;
    height: 300px;
    object-fit: cover;
    cursor: pointer;
}
.carousel::-webkit-scrollbar{
    display: none;
}
.button-box{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
}
.slider-component .btn-a{
    position: absolute;
    cursor: pointer;
}
.slider-component .next-btn{
    top: 45%;
    right: 0%;
}
.slider-component .previous-btn{
    top: 45%;
    left: 0%;
}