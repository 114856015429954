.sourcing-service{
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
    align-items: center;
    font-size: 12px;
}
.sourcing-service h4{
    color: var(--orange);
}
.sourcing-service .btn-a{
    font-size: 8px;
}
.sourcing-service img{
    width: 40px;
}

@media screen and (max-width:700px) {
    .sourcing-service{
        width: 220px;
    }
    .sourcing-service p{
        font-size: 10px;
    }
}
@media screen and (max-width:700px) {
    .sourcing-service{
        width: 150px;
    }
    .sourcing-service p{
        font-size: 8px;
    }
    .sourcing-service img{
        width: 30px;
    }
    .sourcing-service h4{
        font-size: 0.8rem;
    }
}
@media screen and (max-width:400px) {
    .sourcing-service{
        width: 140px;
    }
    .sourcing-service p{
        font-size: 7px;
    }
    .sourcing-service img{
        width: 20px;
    }
    .sourcing-service h4{
        font-size: 0.6rem;
    }
    .sourcing-service .btn-a{
        padding: 0.3rem 0.6rem;
        font-size: 8px;
    }
}