.product-sourcing-page{
    width: 100%;
    min-height: 100vh;
}
.product-sourcing-landing-section{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    background: url("../../images/slider1.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 2rem 2rem;
    padding-left: 4rem;
}
.product-sourcing-landing-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.572);
}
.product-sourcing-landing-section h3{
    color: white;
    position: relative;
    max-width: 600px;
    font-size: 3rem;
}
.product-sourcing-landing-section h3 span{
    color: var(--orange);
}
.product-sourcing-landing-section .btn-a{
    position: relative;
    width: 160px;
}
.ps-second-section{
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    align-items: center;
    padding: 3rem 2rem;
}
.ps-second-section-right{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
    font-size: 14px;
}
.ps-second-section-left img{
    width: 220px;
}

.product-sourcing-page .service-section-top h3{
    color: var(--blue);
}
.product-sourcing-page .service-section-top h3 span{
    color: var(--orange);
}


.ps-forth-section{
    padding: 5rem 2rem;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
}
.ps-forth-section h3 span{
    color: var(--orange);
}
.ps-forth-section p{
    max-width: 600px;
}
.ps-forth-section h4 span{
    color: var(--orange);
}
.ps-forth-section h4{
    margin-top: 2rem;
}
.ps-forth-section p span{
    color: var(--orange);
}
.ps-forth-section h5{
    color: var(--blue);
    margin-top: 1rem;
    position: relative;
}
.ps-forth-section h5::before{
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0%;
    width: 80%;
    height: 2px;
    background-color: var(--blue);
}


.ps-fifth-section{
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
}
.ps-fifth-section h3{
    color: var(--orange);
    font-size: 1.5rem;
    width: 80%;
}
.ps-fifth-section p{
    width: 70%;
    font-size: 14px;
}
.ps-fifth-section img{
    width: 60%;
    margin-top: 2rem;
}




.ps-sixth-section{
    padding: 4rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    background-color: rgb(246, 246, 246);
}
.ps-sixth-section-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.4rem;
}
.ps-sixth-section-top p{
    max-width: 600px;
}
.ps-sixth-section-top h3 span{
    color: var(--orange);
}
.ps-sixth-section-bottom{
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    gap: 3rem;
    flex-wrap: wrap;
}


.ps-seventh-section{
    padding: 4rem 2rem;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}
.ps-seventh-section h3 span{
    color: var(--orange);
}
.ps-seventh-section-middle{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    place-items: center;
    gap: 2rem;
}
.sourcing-agent-card{
    padding: 1.5rem 1.5rem;
    background-color: white;
    border-radius:6px;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    max-width: 450px;
}
.sourcing-agent-card h4{
    color: var(--orange);
}
.sourcing-agent-card p{
    width: 80%;
    font-size: 12px;
}
.ps-seventh-section > p{
    font-size: 12px;
    text-align: center;
    max-width: 600px;
}


.ps-eighth-section{
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    place-items: center;
}
.ps-eighth-section h3 span{
    color: var(--orange);
}
.benefit-section{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
    place-items: center;
}


.ninth-section{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem;
}
.ninth-section-box{
    width: 80%;
    padding: 3rem 2rem;
    background-color: var(--orange);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
    color: white;
    border-radius: 8px;
}
.ninth-section-box h4{
    color: white;
}
.ninth-section-box h4 span{
    color: var(--blue);
}
.ninth-section-box h5{
    color: white;
}
.ninth-section-box .btn-a{
    font-size: 12px;
}

@media screen and (max-width:800px) {
    .product-sourcing-page .service-section-bottom{
        flex-direction: column;
    }
}
@media screen and (max-width:700px) {
    .ps-seventh-section-middle{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        place-items: center;
        gap: 2rem;
    }
    .product-sourcing-landing-section{
        height: 80vh;
    }
    .product-sourcing-landing-section h3{
        font-size: 2rem;
    }
    .ps-second-section{
        flex-direction: column-reverse;
    }
    .ps-forth-section h3{
        font-size: 1.5rem;
    }
    .ps-fifth-section p{
        width: 100%;
    }
}

@media screen and (max-width:600px) {
    .product-sourcing-page p{
        font-size: 12px;
    }
    .ninth-section-box{
        width: 100%;
    }
    .ps-sixth-section-top h3{
        font-size: 1.5rem;
    }
    .ps-eighth-section .benefit-section{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 2rem;
        place-items: center;
    }
    
}
@media screen and (max-width:500px) {
    .ps-fifth-section img{
        width: 80%;
    }
}

@media screen and (max-width:400px) {
    .product-sourcing-landing-section h3{
        font-size: 1.4rem;
    }
    .product-sourcing-landing-section .btn-a{
        font-size: 12px;
    }
    .product-sourcing-landing-section{
        height: 60vh;
    }.product-sourcing-page p{
        font-size: 10px;
    }
    .ps-second-section-right ul li{
        font-size: 10px;
    }
    .ps-second-section img{
        width: 100px;
    }
    .ps-forth-section h3{
        font-size: 1.2rem;
    }
    .ps-forth-section h4{
        font-size: 0.8rem;
    }
    .ps-fifth-section h3{
        font-size: 1rem;
    }
    .ps-sixth-section{
        padding: 4rem 1rem;
    }
    .ps-sixth-section-bottom{
        gap: 1rem;
    }
    .ps-sixth-section-top h3{
        font-size: 0.9rem;
    }
    .ninth-section-box h4{
        font-size: 0.8rem;
    }
    .ninth-section-box{
        padding: 2rem 2rem;
    }
    .ninth-section-box .btn-a{
        font-size: 10px;
    }
    .ps-fifth-section h3{
        width: 100%;
    }
}