.pricing-section{
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
}
.pricing-section-left img{
    width: 300px;
}
.pricing-section-right{
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.pricing-section-right h3 span{
    color: var(--orange);
}
.rowReverse{
    flex-direction: row-reverse;
}

@media screen and (max-width:800px) {
    .pricing-section{
        flex-direction: column-reverse;
    }
    .pricing-section-right{
        align-items: center;
        text-align: center;
    }
}