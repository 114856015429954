.certificate-page{
    width: 100%;
    min-height: 100vh;
}
.certificate-page-landing-section{
    width: 100%;
    height: 100vh;
    background: url("../../images/certificate-bg.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
    gap: 1rem;
}
.certificate-page-landing-section::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.486);
}
.certificate-page-landing-section h1{
    color: var(--orange);
    position: relative;
}
.certificate-page-landing-section h3{
    position: relative;
    color: white;
}
.certificate-page-landing-section p{
    position: relative;
    max-width: 500px;
    color: white;
    font-size: 12px;
}

.certificate-page-certificate-section{
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    gap: 3rem;
    flex-wrap: wrap;
    padding: 2rem 2rem;
}


@media screen and (max-width:600px) {
    .certificate-page-landing-section h3{
        font-size: 1.2rem;
    }
    .certificate-page-landing-section p{
        font-size: 10px;
    }
    .certificate-page-landing-section{
        height: 80vh;
        padding: 4rem 2rem;
    }
}
@media screen and (max-width:400px) {
    .certificate-page-landing-section h3{
        font-size: 1rem;
    }
    .certificate-page-landing-section p{
        font-size: 8px;
    }
    .certificate-page-landing-section{
        height: 70vh;
        padding: 4rem 2rem;
    }
}