.footer{
    padding: 3rem 2rem;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.footer-top{
    display: grid;
    grid-template-columns: repeat(4,1fr);
}
.footer-cols{
    display: flex;
    flex-direction:column;
    gap: 0.7rem;
    font-size: 14px;
}
.footer-cols .flex-icon-box .icon{
    font-size: 16px;
}
.footer-cols h5{
    color: var(--orange);
}
.footer-top .footer-cols img{
    width: 180px;
}
.footer-middle{
    width: 100%;
    height: 2px;
    background-color: rgb(176, 215, 246);
    margin-top: 2rem;
}
.footer-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.footer-bottom-left{
    display: flex;
    gap: 1rem;
}
.footer-bottom-right a{
    font-size: 12px;
}

.gmap{
    border: none;
}
@media screen and (max-width:900px) {
    .footer-top{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 3rem;
        place-items: center;
    }
}
@media screen and (max-width:450px) {
    .footer-top{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 2rem;
        place-items:center;
    }
    .footer-bottom-left .social-icon{
        font-size: 14px;
    }
    .footer-bottom-right a{
        font-size: 10px;
    }
}