.gallery-page{
    width: 100%;
    min-height: 100vh;
}
.gallery-page-landing-section{
    padding: 2rem 2rem;
    padding-top: 150px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;
}
.gallery-page-landing-section-right img{
    width: 350px;
}
.gallery-page-landing-section-left{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 500px;
}
.gallery-page-landing-section-left h3{
    font-size: 2.5rem;
}
.gallery-page-landing-section-left h3 span{
    color: var(--orange);
}
.gallery-page-landing-section-left p{
    font-size: 14px;
}

.gallery-page-image-section{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 2.5rem;
    flex-wrap: wrap;
    padding: 2rem 2rem;
}



@media screen and (max-width:900px) {
    .gallery-page-landing-section-right img{
        width: 250px;
    }   
    .gallery-page-landing-section-left h3{
        font-size: 2rem;
    }
    .gallery-page-landing-section-left p{
        font-size: 12px;
    }
}

@media screen and (max-width:750px) {
    .gallery-page-landing-section{
        flex-direction: column;
        text-align: center;
    }
    .gallery-page-landing-section-right img{
        width: 350px;
    }
}

@media screen and (max-width:450px) {
    .gallery-page-landing-section-left h3{
        font-size: 1.5rem;
    }
    .gallery-page-landing-section-left p{
        font-size: 10px;
    }
    .gallery-page-landing-section-right img{
        width: 300px;
    }
    .gallery-page-landing-section{
        padding-top: 90px;
        height: auto;
    }
    .gallery-page-landing-section-right img{
        width: 270px;
    }
}

