  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px;
    width: 100%;
    margin-top: 100px;
    padding-bottom: 2rem;
  }
  
  .gallery-item {
    width: 250px;
    height: 250px;
    cursor: pointer;
  }
  
  .gallery-image, .gallery-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    position: relative;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .popup-media {
    width: 450px;
    max-height: 80vh;
    object-fit: cover;
  }
  
  .popup-controls {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .popup-controls button {
    cursor: pointer;
  }
  
  .popup-content .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 34px;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--orange);
    padding: 8px;
    z-index: 100;
  }
  
  
  @media screen and (max-width:600px) {
    .popup-media {
      width:350px;
      height: auto;
      max-height: 400px;
      object-fit: cover;
    }
     
  }
  @media screen and (max-width:400px) {
    .popup-media {
      width:270px;
      height: auto;
      max-height: 400px;
      object-fit: cover;
    }
    .popup-content .close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 16px;
      border-radius: 50%;
      cursor: pointer;
      background-color: var(--orange);
      padding: 4px 4px;
      z-index: 100;
    }
    .popup-controls .btn-a {
      padding: 0.3rem 0.6rem;
    }
    
  }