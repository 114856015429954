.appointment-booking-section{
    width: 100%;
    padding: 1rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.appointment-booking-box{
    background-color: white;
    padding: 1.5rem 1.5rem; 
    border : 2px solid var(--blue);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 11;
}
.appointment-booking-box-second-row{
    display: flex;
    align-items: center;
    gap: 3rem;
}
.appointment-booking-box h4{
    color: var(--orange);
}
.appointment-booking-box-second-row .flex-icon-box{
    width: 160px;
}
.flex-icon-box .orange-icon{
    font-size: 18px;
}
.flex-icon-box p{
    font-size: 14px;
}


@media screen and (max-width:950px){
    .appointment-booking-box-second-row{
        display: flex;
        flex-wrap: wrap;
    }   
}
@media screen and (max-width:850px){
    .appointment-booking-box-second-row{
        display: flex;
        justify-content: space-evenly;
    }   
}
@media screen and (max-width:750px){
    .appointment-booking-box-second-row .flex-icon-box{
        width: 120px;
    }
    .flex-icon-box .orange-icon{
        font-size: 12px;
    }
    .flex-icon-box p{
        font-size: 10px;
    }
}
@media screen and (max-width:600px){
    .appointment-booking-box-second-row .flex-icon-box{
        width:auto;
        /* border: 1px solid red; */
    }
    .flex-icon-box .orange-icon{
        font-size: 12px;
    }
    .flex-icon-box p{
        font-size: 10px;
    }
    .appointment-booking-box-second-row{
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }   
    .appointment-booking-box h4{
        font-size: 1rem;
    }
    .appointment-booking-box .btn-a{
        font-size: 8px;
    }
}

@media screen and (max-width:450px) {
    .appointment-booking-box{
        border: 1px solid var(--blue);
        width: 100%;
        padding: 0.7rem 0.7rem; 
    }
    .appointment-booking-box h4{
        font-size: 0.8rem;
    }
}

@media screen and (max-width:400px) {
    .flex-icon-box .orange-icon{
        font-size: 10px;
    }
    .flex-icon-box p{
        font-size: 8px;
    }
    .appointment-booking-box-second-row{
        display: flex;
        justify-content: space-between;
        gap: 0.7rem;
    }   
    .appointment-booking-box h4{
        font-size: 0.6rem;
        font-weight: lighter;
    }
    .appointment-booking-box .btn-a{
        font-size: 8px;
        padding: 0.4rem 0.8rem;
    }
}