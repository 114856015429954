.error-page{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #16120f;
}
.error-page img{
    width: 330px;
}