.division-card{
    padding: 1rem 1rem;
    border-radius: 8px;
    width: 300px;
    height: 250px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.6rem;
    overflow: hidden;
    transition: 0.4s;
    border: 2px solid transparent;
}
.division-card:hover{
    border-color: var(--orange);
}
.division-card img{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 9;
}
.division-card::before{
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.583);
    z-index: 10;
}
.division-card-icon{
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 11;
    border-radius: 4px;
}
.division-card-icon .dc-icon{
    font-size: 14px;
}
.division-card h6{
    position: relative;
    z-index: 11;
    color: white;
    font-size: 1rem;
}
.division-card p{
    position: relative;
    z-index: 11;
    color: white;
    font-size: 12px;
}

@media screen and (max-width:850px) {
    .division-card p{
        font-size: 12px;
    }
}