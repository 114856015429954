.image-folder{
    width: 280px;
    border-radius: 8px;
    cursor: pointer;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: 0.4s;
    gap: 0.5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.image-folder img{
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.image-folder .btn-a{
    font-size: 10px;
}
.image-screen{
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}
.image-screen-box{
    position: relative;
    width: 80%;
    height: 500px;
    background-color: white;
    padding: 2rem 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    overflow-y: auto;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
}
.image-screen-box .close-box{
    position: absolute;
    top: 2%;
    right: 2%;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.6) 0px 7px 29px 0px;
    padding: 0.5rem 0.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.image-screen-box .close-box .close-icon{
    color: aqua;
    font-size: 14px;
}
.image-screen-box img{
    width: 150px;
    height: 150px;
    object-fit: cover;
}